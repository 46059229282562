import * as React from "react";
import {graphql, PageProps} from "gatsby";

import Layout from "../../../components/layout";
import Hero from '../../../components/hero';
import TrustpilotCarousel from "../../../components/trustpilot/trustpilot-carousel";
import LocationsFilter from "../../../components/locations-filter";
import TemplateHead from "../../../components/template-head";

type DataProps = {
  allLocationsByTypeHub: any,
  allLocations: any,
  allChurchesByHub: any,
  allCemeteriesByHub: any,
  allCrematoriumsByHub: any,
  allBurialGroundsByHub: any,
  slug: any,
  currentType: any,
  currentHub: any
}

export default function Locations({
                                    data: {
                                      currentType,
                                      currentHub,
                                      allLocations,
                                      allChurchesByHub,
                                      allCemeteriesByHub,
                                      allCrematoriumsByHub,
                                      allBurialGroundsByHub,
                                      allLocationsByTypeHub,
                                    }
                                  }: PageProps<DataProps>) {

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--locations pl-4 pr-4"
        bgImageClassName="hero-image hero-image--blog"
        contentClassName="hero-content hero-content--blog"
        altImages="Fenix Funeral Directors"
        imageGlow={"/images/glow.png"}
        breadcrumb={[{name: 'Fenix Home', slug: '/'}, {
          name: 'Locations',
          slug: '/locations/'
        }, {
          name: currentType.title + ' in ' + currentHub.title,
          slug: '/locations/' + currentType.slug + '/' + currentHub.urlPart + '/'
        }]}
      >
      </Hero>
      <LocationsFilter
        locationsData={allLocationsByTypeHub.nodes}
        locationsChurchesHub={allChurchesByHub}
        locationsCemeteriesHub={allCemeteriesByHub}
        locationsCrematoriumsHub={allCrematoriumsByHub}
        locationsBurialGroundsHub={allBurialGroundsByHub}
        locationsDataAll={allLocations}
        contextDataType={currentType}
        contextDataHub={currentHub}
      />
      <TrustpilotCarousel/>
    </Layout>
  )
}

export function Head({data: {allLocationsByTypeHub, currentType, currentHub}}) {

  const pageTitle = '▷ ' + allLocationsByTypeHub.nodes.length + ' ' + currentType.title.toLowerCase() + ' in ' + currentHub.title + '  • Fenix Funeral Directors'
  const pageDescription = 'Arrange funeral in UK → For more information about ' + allLocationsByTypeHub.nodes.length + ' ' + currentType.title.toLowerCase() + ' for funeral in ' + currentHub.title + ' ✓ We help you with bookings | Fenixfuneral.co.uk'
  const pageLink = 'https://fenixfuneral.co.uk/locations/' + currentType.slug + '/' + currentHub.urlPart + '/'

  return (
    <TemplateHead
      title={pageTitle}
      description={pageDescription}
      shortlink={pageLink}
      alternate={pageLink}
      canonical={pageLink}
      og={{
        url: pageLink,
        title: pageTitle,
        description: pageDescription
      }}
      ldjsonbreadcrumb={`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
            "@type": "ListItem",
            "position": "1",
            "name": "Fenix Home","item": "https://fenixfuneral.co.uk/"
            },
            {
            "@type": "ListItem",
            "position": "2",
            "name": "Locations","item": "https://fenixfuneral.co.uk/locations/"
            },
            {
            "@type": "ListItem",
            "position": "3",
            "name": "${currentType.title} in ${currentHub.title}","item": "https://fenixfuneral.co.uk/locations/${currentType.slug}/${currentHub.urlPart}/"
            }
          ]
        }`
      }
    />
  );
}

export const pageQuery = graphql`
    fragment locationData on STRAPI_LOCATIONConnection {
        nodes {
            strapi_id
            title
            slug
            slugFull
            images {
                awsThumbnail
                awsImage
            }
            type {
                slug
                title
            }
            hub {
                title
                urlPart
            }
            city {
                title
                cityUrl
            }
        }
    }
    query ($type__slug: String, $hub__urlPart: String) {
        allLocationsByTypeHub: allStrapiLocation(
            filter: {type: {slug: {eq: $type__slug}}, hub: {urlPart: {eq:$hub__urlPart}}}
            sort: {fields: title, order: ASC}) {
            ...locationData
        }
        allChurchesByHub: allStrapiLocation(filter: {type: {slug: {eq: "churches"}}, hub: {urlPart: {eq:$hub__urlPart}}}) {
            edges {
                node {
                    strapi_id
                }
            }
        }
        allCemeteriesByHub: allStrapiLocation(filter: {type: {slug: {eq: "cemeteries"}}, hub: {urlPart: {eq:$hub__urlPart}}}) {
            edges {
                node {
                    strapi_id
                }
            }
        }
        allCrematoriumsByHub: allStrapiLocation(filter: {type: {slug: {eq: "crematoriums"}}, hub: {urlPart: {eq:$hub__urlPart}}}) {
            edges {
                node {
                    strapi_id
                }
            }
        }
        allBurialGroundsByHub: allStrapiLocation(filter: {type: {slug: {eq: "woodland-burial-grounds"}}, hub: {urlPart: {eq:$hub__urlPart}}}) {
            edges {
                node {
                    strapi_id
                }
            }
        }
        currentType: strapiLocationtype(slug: {eq: $type__slug}) {
            title
            slug
            strapi_id
        }
        currentHub: strapiHub(urlPart: {eq: $hub__urlPart}) {
            strapi_id
            title
            urlPart
        }
    }
`;